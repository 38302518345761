import { useEffect, useState } from 'react';
import api from '../data/api.json';

export default function Youtube() {
  const YOUTUBE_SEARCH_API_URI = "https://www.googleapis.com/youtube/v3/search?";

  const [word, setWord] = useState("ヰ世界情緒");
  const [videoId, setVideoId] = useState("");
  // 1日100回までしかsearchAPI実行できない 太平洋時間(PT)0時にリセット
  // const [num, setNum] = useState(0);

  useEffect(() => {
    // クエリ文字列を定義する
    const params = {
      key: api['youtube-api-key'],
      q: word, // 検索キーワード
      type: "video", // video,channel,playlistから選択できる
      maxResults: "1", // 結果の最大数
      order: "viewCount", // 結果の並び順を再生回数の多い順に
    };
    const queryParams = new URLSearchParams(params);

    // APIをコールする
    fetch(YOUTUBE_SEARCH_API_URI + queryParams)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("API success:", result);

          if (result.items && result.items.length !== 0) {
            const firstItem = result.items[0];
            setVideoId(firstItem.id.videoId);
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }, [word]);

  const handleSearch = () => {
    const searchText = (document.getElementById('search-text') as HTMLInputElement).value;
    setWord(searchText);
    console.log(searchText);
  }

  return(
    <>
      <div className="w-full max-w-min mx-auto mt-6 px-8 pt-4 pb-12 bg-gray-100 shadow-lg rounded-xl">
        <div className="text-xl">再生回数の多い動画を表示する</div>
        <div className="inline-frex m-3">
          <input type="text" id="search-text" placeholder={word} />
          <button onClick={handleSearch} className="bg-blue-200">検索</button>
        </div>
        <iframe title="YouTube" id="player" width="640" height="360" src={"https://www.youtube.com/embed/" + videoId} allowFullScreen />
      </div>
    </>
  );
}