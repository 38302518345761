import { Link, Outlet } from 'react-router-dom';
import content from '../data/content.json';

export default function Layout() {
  return(
    <>
      <div className="bg-green-200 w-full h-screen">
        <Menu />
        <Outlet />
      </div>
    </>
  );
}

// 画面上部ヘッダー
function Menu() {
  return(
    <>
      <header className="inline-flex w-full h-24 bg-gray-200 items-center">
        <Link to="/" className='ml-12 mr-auto text-4xl'>React勉強録</Link>
        <nav>
          {
            // content.menuの数、リスト生成
            function () {
              const list = [];
              for (let i = 0; i < content.menu.length; i++) {
                list.push(
                  <li className='list-none inline-block p-2.5'>
                    <Link to={content.menu[i].href}>{content.menu[i].name}</Link>
                  </li>
                );
              }
              return <ul className=''>{list}</ul>
            }()
          }
        </nav>
      </header>
    </>
  );
}